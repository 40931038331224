let awsRegion = 'us-east-1';

export default {
 api_urls: {
   reporting: 'https://gubnwtp694.execute-api.us-east-1.amazonaws.com/api',
   geojson: 'https://qxoj716cd7.execute-api.us-east-1.amazonaws.com/api',
   overlays: 'https://e22quf317d.execute-api.us-east-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 191,
 crm_base_url: 'https://crm.staging.riotinto.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.riotinto.forwoodsafety.com?redirect_uri=https://geoeditor.staging.riotinto.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.riotinto.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-1_xP2FSZ7LB',
   userPoolWebClientId: '91u8nhe3dfrih96egj13p73q3',
   cookieStorage: {
     domain: '.staging.riotinto.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'staging'
};
